import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import BinodonSwiper from "../../../Component/BinodonSwiper";
import { AuthContext } from "../../../Context/AuthProvider";

const BinodonNews = () => {
  const {data} = useContext(AuthContext);
  const [newsData, setNewsData] = useState([]);

  useEffect(() => {
    const fetchLeadNews = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/category`,
          { params: { number: 6, category: "বিনোদন" } }
        );
        const dt = response.data;
        setNewsData(dt);
      } catch (error) {
        console.error("Error fetching news titles:", error);
      }
    };

    fetchLeadNews();
  }, []);


  if(!data?.length){
    return <></>
  }

  return (
    <>
      {newsData?.length ? (
        <div className="my-10">
          <div className="flex items-center w-full mt-6 -mb-10">
            <div className="w-full">
              <p className="w-full border-b border-gray-500"></p>
              <p className="w-full mt-1 border-b border-gray-500"></p>
            </div>
            <p className="px-3 text-3xl font-bold">বিনোদন</p>
            <div className="w-full">
              <p className="w-full border-b border-gray-500"></p>
              <p className="w-full mt-1 border-b border-gray-500"></p>
            </div>
          </div>
          <div className="mt-20">
            <BinodonSwiper data={newsData} path={"entertainment"} />
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default BinodonNews;
