import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import NewsTabs from "../../../Component/NewsTabs";
import { AuthContext } from "../../../Context/AuthProvider";

const Sylhet = () => {
  const { setLoading, setScrollY, data } = useContext(AuthContext);
  const [newsData, setNewsData] = useState([]);

  useEffect(() => {
    const fetchLeadNews = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/category`,
          { params: { number: 6, category: "সিলেট" } }
        );
        const dt = response.data;
        setNewsData(dt);
      } catch (error) {
        console.error("Error fetching news titles:", error);
      }
    };

    fetchLeadNews();
  }, []);

  if (!data?.length) {
    return <div></div>;
  }

  return (
    <>
      {newsData?.length ? (
        <div>
          <div className="flex items-center w-full my-8">
            <div className="w-full">
              <p className="w-full border-b border-gray-500"></p>
              <p className="w-full mt-1 border-b border-gray-500"></p>
            </div>
            <p className="px-3 text-3xl font-bold">সিলেট</p>
            <div className="w-full">
              <p className="w-full border-b border-gray-500"></p>
              <p className="w-full mt-1 border-b border-gray-500"></p>
            </div>
          </div>

          <div className="flex flex-col gap-8 md:flex-row">
            <div className="md:w-[90%] flex flex-col md:grid grid-cols-2 lg:grid lg:grid-cols-3 gap-8">
              {newsData?.map((d, index) => {
                return (
                  <Link
                    to={`/news/${d?._id}`}
                    state={d}
                    key={index}
                    className="shadow-md p-2 max-w-80 flex flex-col"
                  >
                    <img
                      className="md:max-w-80 rounded-md"
                      src={d?.img}
                      alt="img"
                      loading="lazy"
                    />
                    <h3 className="text-lg my-4 font-bold hover:text-blue-600">
                      {d?.title}
                    </h3>
                  </Link>
                );
              })}
            </div>
            <Link
              onClick={() => setScrollY(true)}
              to="/sylhet"
              className="bg-blue-500 text-white font-bold py-2 px-6 flex justify-center items-center w-[80px] mx-auto rounded-md md:hidden"
            >
              আরও
            </Link>
            <div>
              <NewsTabs setLoading={setLoading} />
            </div>
          </div>
          <Link
            onClick={() => setScrollY(true)}
            to="/sylhet"
            className="bg-blue-500 text-white font-bold py-2 px-6 md:flex m-10 justify-center items-center w-[80px] mx-auto rounded-md hidden"
          >
            আরও
          </Link>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Sylhet;
