import React, { useContext } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, FreeMode, Pagination } from "swiper";
import { Link } from "react-router-dom";
import { AuthContext } from "../Context/AuthProvider";

const SorboseshNewsSwiper = () => {
  const { data } = useContext(AuthContext);

  return (
    <>
      <Swiper
        speed={1000}
        slidesPerView={1}
        spaceBetween={30}
        freeMode={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        loop={true}
        pagination={{
          clickable: true,
        }}
        modules={[FreeMode, Autoplay, Pagination]}
      >
        {data?.slice(0, 6)?.map((d, index) => (
          <SwiperSlide key={index}>
            <Link
              to={`/news/${d?._id}`}
              state={d}
              className="flex flex-col md:flex-row justify-center items-center gap-4 md:w-[80%] mx-auto md:mb-3 h-[335px]"
            >
              <div className="md:w-1/2">
                <img
                  className="mx-auto rounded-md h-[170px] w-[304px] md:h-[290px] md:w-[448px]"
                  src={d?.img}
                  alt="img"
                  loading="lazy"
                />
              </div>
              <div className="md:w-1/2">
                <h2 className="text-xl md:text-3xl font-bold text-white hover:text-blue-600">
                  {d?.title}
                </h2>
              </div>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
      <style>
        {`
          .swiper-pagination-bullet {
            background-color: white;
          }
          .swiper-wrapper {
            height: 360px;
          }
        `}
      </style>
    </>
  );
};

export default SorboseshNewsSwiper;
