import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../Context/AuthProvider';

const PrivateRoutes = ({children}) => {
    const {user, isLoading} = useContext(AuthContext);
    const location = useLocation();

    if(isLoading){
        return <div className="text-md font-bold text-center my-10 min-h-[70vh]">Loading...</div>
    }

    if(user && user?.uid){
        return children;
    }

    return (
       <Navigate to="/login" state={{from: location}} replace/>
    );
};

export default PrivateRoutes;