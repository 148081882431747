import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../Context/AuthProvider";

const Vromon = () => {
  const { setScrollY, data } = useContext(AuthContext);
  const [newsData, setNewsData] = useState([]);

  useEffect(() => {
    const fetchLeadNews = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/category`,
          { params: { number: 8, category: "ভ্রমণ" } }
        );
        const dt = response.data;
        setNewsData(dt);
      } catch (error) {
        console.error("Error fetching news titles:", error);
      }
    };

    fetchLeadNews();
  }, []);

  if (!data?.length) {
    return <></>;
  }

  return (
    <>
      {newsData?.length ? (
        <div className="my-10 bg-gray-800 px-4 py-10">
          <div className="flex items-center w-full my-8">
            <div className="w-full">
              <p className="w-full border-b border-gray-100"></p>
              <p className="w-full mt-1 border-b border-gray-100"></p>
            </div>
            <p className="px-3 text-3xl text-white font-bold">ভ্রমণ</p>
            <div className="w-full">
              <p className="w-full border-b border-gray-100"></p>
              <p className="w-full mt-1 border-b border-gray-100"></p>
            </div>
          </div>
          <div className="grid md:grid-cols-3 lg:grid-cols-4 gap-4">
            {newsData?.map((d, index) => {
              return (
                <Link
                  to={`/news/${d?._id}`}
                  state={d}
                  key={index}
                  className="border border-white rounded-md"
                >
                  <img
                    className="w-full"
                    src={d?.img}
                    alt="img"
                    loading="lazy"
                  />
                  <h4 className="text-xl text-white font-bold p-2 hover:text-blue-600">
                    {d?.title}
                  </h4>
                </Link>
              );
            })}
          </div>
          <Link
            onClick={() => setScrollY(true)}
            to="/traveling"
            className="bg-blue-500 text-white font-bold py-2 px-6 flex m-10 justify-center items-center w-[80px] mx-auto rounded-md"
          >
            আরও
          </Link>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Vromon;
