import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../Context/AuthProvider";

const NewsTabs = ({ setLoading }) => {
  const { data, view } = useContext(AuthContext);
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  return (
    <>
      {data?.length ? (
        <div className="border-2 border-blue-500 max-w-[500px]">
          <div className="flex top-0">
            <button
              className={`w-full py-2 px-4 mr-2 ${
                activeTab === 1
                  ? "bg-blue-500 text-white"
                  : "bg-white text-blue-500"
              }`}
              onClick={() => handleTabClick(1)}
            >
              সর্বশেষ
            </button>
            <button
              className={`w-full  py-2 px-4 ${
                activeTab === 2
                  ? "bg-blue-500 text-white"
                  : "bg-white text-blue-500"
              }`}
              onClick={() => handleTabClick(2)}
            >
              সর্বাধিক পঠিত
            </button>
          </div>
          <div className="mt-4 h-[450px] overflow-y-scroll p-2">
            {activeTab === 1 && (
              <>
                {data?.map((d, index) => (
                  <Link
                    onClick={() => setLoading(false)}
                    to={`/news/${d?._id}`}
                    key={index}
                    className="flex gap-2 border-b items-center my-2"
                    state={d}
                  >
                    <img
                      className="w-[87px] h-16 rounded-md"
                      src={d?.img}
                      alt="img"
                    />
                    <p className="text-md font-semibold hover:text-blue-600">
                      {d?.title}
                    </p>
                  </Link>
                ))}
              </>
            )}
            {activeTab === 2 && (
              <>
                {view?.map((d, index) => (
                  <Link
                    onClick={() => setLoading(false)}
                    to={`/news/${d?._id}`}
                    key={index}
                    className="flex gap-2 border-b items-center my-2"
                    state={d}
                  >
                    <img
                      className="w-[87px] h-16 rounded-md"
                      src={d?.img}
                      alt="img"
                    />
                    <p className="text-md font-semibold hover:text-blue-600">
                      {d?.title}
                    </p>
                  </Link>
                ))}
              </>
            )}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default NewsTabs;
