import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../Context/AuthProvider";

const useToken = (email, password) => {
  const { logOut } = useContext(AuthContext);
  const [token, setToken] = useState("");

  useEffect(() => {
    if (email) {
      fetch(
        `${process.env.REACT_APP_URL}/jwt?email=${email}&password=${password}`
      )
        .then((res) => res.json())
        .then((data) => {
          if (data?.khoboraccess_token) {
            const accessToken = data.khoboraccess_token;
            setToken(accessToken);
            const expirationDate = new Date();
            expirationDate.setDate(expirationDate.getDate() + 7);

            // Save the token and expiration date in a cookie
            document.cookie = `khoboraccess_token=${accessToken}; expires=${expirationDate.toUTCString()}; path=/`;
            document.cookie = `khoboraccess_token_expiration=${expirationDate.toUTCString()}; expires=${expirationDate.toUTCString()}; path=/`;
          }
        })
        .catch((err) => console.error(err));
    }
  }, [email, password, logOut]);

  return [token];
};

export default useToken;
