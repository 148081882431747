import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import useAdmin from '../hooks/useAdmin';
import { AuthContext } from '../Context/AuthProvider';

const AdminRoutes = ({children}) => {
    const {user, isLoading} = useContext(AuthContext);
    const [isAdmin, loading] = useAdmin(user?.email);
    const location = useLocation();


    if(loading || isLoading){
        return <div className="text-md font-bold text-center my-10 min-h-[70vh]">Loading...</div>
    };
    if(user && user?.uid && isAdmin){
        return children;
    };

    return (
        <Navigate to='/login' state={{from: location}} replace>
            
        </Navigate>
    );
};

export default AdminRoutes;