import { toast } from "react-hot-toast";

const CopyUrl = (url) => {  
  navigator.clipboard
    .writeText(url)
    .then(() => {
      toast.success("Copied");
    })
    .catch((error) => {
      console.error("Failed to copy URL to clipboard:", error);
    });
};
export default CopyUrl;
