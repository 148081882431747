export const getEngCategory = (category) => {
    if(category === "সিলেট"){
        return "/sylhet"
    }
    if(category === "জাতীয়") {
        return "/national"
    }
    if(category === "আন্তর্জাতিক") {
        return "/international"
    }
    if(category === "খেলা") {
        return "/sports"
    }
    if(category === "স্বাস্থ্য") {
        return "/health"
    }
    if(category === "বাণিজ্য") {
        return "/trade"
    }
    if(category === "বিনোদন") {
        return "/entertainment"
    }
    if(category === "সাহিত্য") {
        return "/literature"
    }
    if(category === "শিক্ষা") {
        return "/education"
    }
    if(category === "প্রযুক্তি") {
        return "/technology"
    }
    if(category === "ধর্ম") {
        return "/religion"
    }
    if(category === "ভ্রমণ") {
        return "/traveling"
    }
    if(category === "মতামত") {
        return "/opinion"
    }
    if(category === "সব খবর") {
        return "/allNews"
    }
};

export const getBanCategory = (category) => {
    if(category === "companiganj"){
        return "কোম্পানীগঞ্জ"
    }
    if(category === "sylhet"){
        return "সিলেট"
    }
    if(category === "national") {
        return "জাতীয়"
    }
    if(category === "international") {
        return "আন্তর্জাতিক"
    }
    if(category === "sports") {
        return "খেলা"
    }
    if(category === "health") {
        return "স্বাস্থ্য"
    }
    if(category === "trade") {
        return "বাণিজ্য"
    }
    if(category === "entertainment") {
        return "বিনোদন"
    }
    if(category === "literature") {
        return "সাহিত্য"
    }
    if(category === "education") {
        return "শিক্ষা"
    }
    if(category === "technology") {
        return "প্রযুক্তি"
    }
    if(category === "religion") {
        return "ধর্ম"
    }
    if(category === "traveling") {
        return "ভ্রমণ"
    }
    if(category === "opinion") {
        return "মতামত"
    }
    if(category === "allNews") {
        return "সব খবর"
    }
};