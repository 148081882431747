import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./BinodonSwiper.css";
import { FreeMode, Autoplay, Pagination } from "swiper";
import { Navigation } from "swiper";
import { AuthContext } from "../Context/AuthProvider";

const BinodonSwiper = ({ data, path }) => {
  const { setScrollY } = useContext(AuthContext);

  return (
    <>
      <Swiper
        speed={1000}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        loop={true}
        slidesPerView={3}
        spaceBetween={10}
        freeMode={true}
        navigation={true}
        pagination={{
          clickable: true,
        }}
        modules={[FreeMode, Autoplay, Pagination, Navigation]}
        className="mySwiper"
        breakpoints={{
          40: {
            slidesPerView: 1,
          },
          640: {
            slidesPerView: 2,
          },
          1000: {
            slidesPerView: 3,
          },
          1300: {
            slidesPerView: 3,
          },
        }}
      >
        {data?.map((d, index) => (
          <SwiperSlide key={index}>
            <Link
              to={`/news/${d?._id}`}
              state={d}
              className="bg-gray-100 mt-10 pb-10 rounded-md md:w-[80%] mx-auto"
            >
              <img
                className="w-full mx-auto rounded-md"
                src={d?.img}
                alt="img"
                loading="lazy"
              />
              <p className="text-xl font-bold py-2 px-2 hover:text-blue-600">
                {d?.title}
              </p>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
      <Link
        onClick={() => setScrollY(true)}
        to={`/${path}`}
        className="bg-blue-500 text-white font-bold py-2 px-6 flex m-10 justify-center items-center w-[80px] mx-auto rounded-md"
      >
        আরও
      </Link>
    </>
  );
};

export default BinodonSwiper;
