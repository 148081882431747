import React from "react";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
  window.scrollTo(0, 0);
  return (
    <div className="my-10 p-2 max-w-[800px] mx-auto border-[1px] rounded-md font-sans min-h-screen">
      <div>
        <div className="mb-4">
          <h2 className="text-base font-bold mb-2">
            Privacy Policy for Ajker Khobor
          </h2>
          <p className="text-gray-600 text-sm">
            Thank you for visiting AJker Khobor. We value your privacy and are
            committed to protecting your personal information. This Privacy
            Policy explains how we collect, use, disclose, and safeguard your
            personal data. By accessing or using our website, you consent to the
            terms outlined in this policy.
          </p>
        </div>
        <div className="mb-4">
          <h2 className="text-base font-bold mb-2">Information We Collect</h2>
          <p className="text-gray-600 text-sm">
            <span className="font-bold">Personal Information: </span>
            We may collect personal information, such as your name, email
            address, and phone number, when you contact us, or participate in
            any interactive features of our website.
          </p>
          <p className="text-gray-600 text-sm mt-1">
            <span className="font-bold">Non-Personal Information: </span>
            Sometime We collect your ip address for statistical and analytical
            purposes.
          </p>
        </div>
        <div className="mb-4">
          <h2 className="text-base font-bold mb-2">
            How We Use Your Information
          </h2>
          <p className="text-gray-600 text-sm">
            We will not use any of your information.
          </p>
        </div>
        <div className="mb-4">
          <h2 className="text-base font-bold mb-2">
            Cookies and Tracking Technologies
          </h2>
          <p className="text-gray-600 text-sm">
            We do not use any kind of Cookies and Tracking Technologies.
          </p>
        </div>
        <div className="mb-4">
          <h2 className="text-base font-bold mb-2">
            Disclosure of Your Information
          </h2>
          <p className="text-gray-600 text-sm">
            We do not sell or share your personal information with third
            parties, except as required by law or as necessary to provide you
            with our services.
          </p>
        </div>
        <div className="mb-4">
          <h2 className="text-base font-bold mb-2">Data Security</h2>
          <p className="text-gray-600 text-sm">
            We take reasonable measures to protect your personal information
            from unauthorized access, disclosure, alteration, or destruction.
            However, please be aware that no method of data transmission over
            the Internet is completely secure.
          </p>
        </div>
        <div className="mb-4">
          <h2 className="text-base font-bold mb-2">
            Changes to this Privacy Policy
          </h2>
          <p className="text-gray-600 text-sm">
            We may update this Privacy Policy from time to time to reflect
            changes to our practices or for other operational, legal, or
            regulatory reasons. We will post the revised policy on this page
            with an updated effective date.
          </p>
        </div>
        <div className="mb-4">
          <h2 className="text-base font-bold mb-2">Contact Us</h2>
          <p className="text-gray-600 text-sm">
            If you have any questions, concerns, or requests regarding this
            Privacy Policy, please{" "}
            <Link to="/contact-us" className="text-blue-500 font-bold">
              contact us
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
