import Navbar from "../Pages/Shared/Navbar";
import Footer from "../Pages/Shared/Footer";
import { Outlet } from "react-router-dom";
import NewsScroller from "../Component/NewsScroller";
import LiveClock from "react-live-clock";
import { AuthContext } from "../Context/AuthProvider";
import { useContext } from "react";
import { FaArrowUp } from "react-icons/fa";
const Main = () => {
  const { data } = useContext(AuthContext);
  

  const handleBackToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div>
      <Navbar />
      <div className="mt-[110px] md:-mt-2 md:max-w-[1500px] md:mx-auto">
        <div className="mx-3 my-4">
          <div className={`w-full mx-auto relative`}>
            <NewsScroller />
            {data?.length ? (
              <div className="mt-1">
                <LiveClock
                  className="bg-gray-200 border border-green-500 text-xs md:text-md pt-1 text-black font-bold px-2"
                  format={"hh:mm:ss A"}
                  ticking={true}
                  timezone={"Asia/Dhaka"}
                />
              </div>
            ) : (
              ""
            )}
            <div
              onClick={handleBackToTop}
              className="cursor-pointer p-4 bg-red-500 rounded-full z-50 text-2xl text-white fixed top-[90%] right-2 duration-1000 ease-in-out"
            >
              <FaArrowUp />
            </div>
          </div>
          <Outlet />
        </div>
      </div>
      <Footer/>
      
    </div>
  );
};

export default Main;
