import React, { useRef, useMemo } from "react";
import JoditEditor from "jodit-react";
import "./TextEditor.css";

const TextEditor = ({ description, setDescription }) => {
  const editor = useRef(null);

  const config = useMemo(
    () => ({
      readonly: false,
      placeholder: "Description",
      toolbarSticky: false, // Disables Jodit's default sticky behavior
    }),
    []
  );

  return (
    <div className="text-editor-container">
      <JoditEditor
        ref={editor}
        value={description}
        config={config}
        tabIndex={1}
        onBlur={(newContent) => setDescription(newContent)}
        // onChange={(newContent) => {}}
      />
    </div>
  );
};

export default TextEditor;
