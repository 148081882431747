import React from "react";
import { FaUserAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import getCookie from "../../Component/getCookie";
import axios from "axios";
import toast from "react-hot-toast";

const DashboardNewsForSm = ({
  data,
  setDeleteModal,
  setEditModal,
  setEditData,
  setDeleteId,
  handleLoad,
  setLoading,
}) => {
  const cookie = getCookie("khoboraccess_token");

  const handleHideToggle = async (id) => {
    setLoading(true);

    try {
      const res = await axios.patch(
        `${process.env.REACT_APP_URL}/hide-news/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${cookie}`,
          },
        }
      );
      if (res?.status === 200) {
        handleLoad();
        setLoading(false);
        toast.success("Success");
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  if (!data?.length) {
    return <div className="text-center">No data found</div>;
  }

  return (
    <div className="mx-1">
      {data?.map((d) => (
        <div
          className="shadow-lg p-2 rounded-md border border-black mb-2"
          key={d?._id}
        >
          <div className="flex gap-2">
            <img src={d?.img} className="w-20 h-20 rounded-md" alt="" />
            <div>
              <p className="">
                <Link className="hover:text-blue-500" to={`/news/${d?._id}`}>
                  {d?.title}
                </Link>
              </p>
              <div className="flex gap-3 bg-gray-100 p-2 rounded-md items-center">
                <FaUserAlt />
                <span>{d?.postBy}</span>
                <span className="text-right">Cate: {d?.category}</span>{" "}
              </div>
            </div>
          </div>
          <div className="flex gap-3 justify-center mt-2">
            <button
              onClick={() => {
                setEditModal(true);
                setEditData(d);
              }}
              className="bg-blue-500 px-3 py-1 rounded-md text-white text-sm"
            >
              Edit
            </button>
            <button
              onClick={() => {
                setDeleteModal(true);
                setDeleteId(d?._id);
              }}
              className="bg-red-500 px-3 py-1 text-white rounded-md text-sm"
            >
              Delete
            </button>
            <button
              onClick={() => {
                handleHideToggle(d?._id);
              }}
              className={`flex gap-1 justify-center items-center px-3 py-1 text-white rounded-md text-xs ${
                d?.hide ? "bg-red-500" : "bg-green-500"
              }`}
            >
              {d?.hide ? (
                <>
                  <span>Hidden</span> <span>🔒</span>
                </>
              ) : (
                <>
                  <span>Hide</span> <span>🌎</span>
                </>
              )}
            </button>
          </div>
        </div>
      ))}
      <div className="flex justify-center my-4">
        {data?.length > 9 && (
          <button
            onClick={handleLoad}
            className="bg-gray-600 text-white rounded-md px-4 py-2 font-bold"
          >
            আরও
          </button>
        )}
        {!data?.length && (
          <h2 className="text-2xl text-center my-4">No Results Found</h2>
        )}
      </div>
    </div>
  );
};

export default DashboardNewsForSm;
