import React, { useContext, useEffect, useState } from "react";
import { Toaster, toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { FaEye, FaRegEyeSlash } from "react-icons/fa";
import useToken from "../../hooks/useToken";
import { AuthContext } from "../../Context/AuthProvider";
import axios from "axios";

const Login = () => {
  const [adminEmail, setAdminEmail] = useState("");
  const [adminPassword, setAdminPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [passType, setPassType] = useState(true);
  const { signIn } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [token] = useToken(adminEmail, adminPassword);
  const from = location.state?.from?.pathname || "/";

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    const email = e.target.email.value;
    const password = e.target.password.value;

    axios
      .post(`${process.env.REACT_APP_URL}/login`, { email, password })
      .then((res) => {
        if (res?.status === 200) {
          signIn(email, password)
            .then((res) => {
              if (res?.user?.uid) {
                setAdminEmail(email);
                setAdminPassword(password);
                toast.success("Login Success");
              }
            })
            .catch((err) => {
              toast.error(err?.message?.split("/")[1]?.split(")")[0]);
            });
        }
      })
      .catch((e) => {
        toast?.error("Wrong Username and Password");
        setLoading(false);
      });
  };

  useEffect(() => {
    if (token) {
      navigate(from, { replace: true });
    }
  }, [token, from, navigate]);

  return (
    <div className="w-full max-w-md p-8 space-y-3 rounded-xl bg-gray-200 mx-auto my-10">
      <Toaster />
      <h1 className="text-2xl font-bold text-center">Login</h1>
      <form
        onSubmit={handleSubmit}
        noValidate=""
        action=""
        className="space-y-6"
      >
        <div className="space-y-1 text-sm">
          <label htmlFor="email" className="block">
            Email
          </label>
          <input
            type="email"
            name="email"
            required
            id="email"
            placeholder="Email"
            className="w-full px-4 py-3 rounded-md border-gray-700"
          />
        </div>
        <div className="space-y-1 text-sm relative">
          <label htmlFor="password" className="block">
            Password
          </label>
          <input
            type={passType ? "password" : "text"}
            name="password"
            id="password"
            placeholder="Password"
            className="w-full px-4 py-3 rounded-md border-gray-700 flex"
          />
          <FaEye
            onClick={() => setPassType(true)}
            className={
              !passType
                ? "cursor-pointer absolute right-[10px] top-[32px] w-5 h-5"
                : "hidden"
            }
          />

          <FaRegEyeSlash
            onClick={() => setPassType(false)}
            className={
              passType
                ? "cursor-pointer absolute right-[10px] top-[32px] w-5 h-5"
                : "hidden"
            }
          />
        </div>
        {loading ? (
          <button
            type="submit"
            disabled={true}
            className="block w-full p-3 text-center rounded-sm text-white bg-blue-400 font-bold"
          >
            Sing In...
          </button>
        ) : (
          <button
            type="submit"
            className="block w-full p-3 text-center rounded-sm text-white bg-blue-500 font-bold"
          >
            Sing In
          </button>
        )}
      </form>
    </div>
  );
};

export default Login;
