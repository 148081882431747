import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../Context/AuthProvider";

const Motamot = () => {
  const { setScrollY, data } = useContext(AuthContext);
  const [newsData, setNewsData] = useState([]);

  useEffect(() => {
    const fetchLeadNews = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/category`,
          { params: { number: 7, category: "মতামত" } }
        );
        const dt = response.data;
        setNewsData(dt);
      } catch (error) {
        console.error("Error fetching news titles:", error);
      }
    };

    fetchLeadNews();
  }, []);

  if (!data?.length) {
    return <></>;
  }

  return (
    <>
      {newsData?.length ? (
        <div className="my-10">
          <div className="flex items-center w-full my-8">
            <div className="w-full">
              <p className="w-full border-b border-gray-500"></p>
              <p className="w-full mt-1 border-b border-gray-500"></p>
            </div>
            <p className="px-3 text-3xl font-bold">মতামত</p>
            <div className="w-full">
              <p className="w-full border-b border-gray-500"></p>
              <p className="w-full mt-1 border-b border-gray-500"></p>
            </div>
          </div>
          <div className="bg-[#e8fbe7] flex flex-col md:flex-row gap-10 p-5 rounded-md">
            <div className="shadow-md p-2 max-w-80 flex flex-col md:w-[30%]">
              <img
                className="max-w-80 rounded-md"
                src={newsData[0]?.img}
                alt="img"
                loading="lazy"
              />
              <div className="my-3">
                <Link
                  to={`/news/${newsData[0]?._id}`}
                  className="text-2xl md:text-3xl font-bold text-blue-500 hover:text-red-500"
                  state={newsData[0]}
                >
                  {newsData[0]?.title}
                </Link>
              </div>
            </div>
            <div className="md:grid grid-cols-2 gap-8">
              {newsData?.slice(1)?.map((d, index) => {
                return (
                  <Link
                    key={index}
                    className="my-4 md:my-0 flex justify-between items-center"
                    to={`/news/${d?._id}`}
                  >
                    <h4 className="text-red-500 font-bold text-xl max-w-[75%]">
                      {d?.title}
                    </h4>
                    <div className="flex justify-evenly border-b mb-2 pb-2 border-black items-center gap-4">
                      <img
                        className="w-16 h-16 rounded-full"
                        src={d?.img}
                        alt="img"
                        loading="lazy"
                      />
                    </div>
                  </Link>
                );
              })}
            </div>
          </div>
          <Link
            onClick={() => setScrollY(true)}
            to="/opinion"
            className="bg-blue-500 text-white font-bold py-2 px-6 flex m-10 justify-center items-center w-[80px] mx-auto rounded-md"
          >
            আরও
          </Link>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Motamot;
