import React, { useRef } from "react";
import emailjs from "@emailjs/browser";

const ContactUs = () => {
  window.scrollTo(0, 0);
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_email_service_id,
        process.env.REACT_APP_email_template_id,
        form.current,
        process.env.REACT_APP_email_public_key
      )
      .then(
        (result) => {
          alert("Message sent successfully");
          e.target.reset();
        },
        (error) => {
          alert(error.text);
          e.target.reset();
        }
      );
  };

  return (
    <div className="container mx-auto my-20 md:w-1/3 border border-blue-500 bg-white">
      <div className="p-5 space-y-5 shadow-xl">
        <h4 className="text-center text-3xl">Contact Us</h4>

        <form ref={form} onSubmit={sendEmail}>
          <div className="">
            <div className="grid grid-cols-2 mb-3 gap-3">
              <div className="flex flex-col">
                <input
                  type="text"
                  name="firstName"
                  required
                  className="border border-gray-500 px-4 py-2 focus:outline-none focus:border-blue-500"
                  placeholder="First Name"
                />
              </div>
              <div className="flex flex-col">
                <input
                  type="text"
                  name="lastName"
                  required
                  className="border border-gray-500 px-4 py-2 focus:outline-none focus:border-blue-500"
                  placeholder="Last Name"
                />
              </div>
            </div>
            <div className="flex flex-col w-full">
              <input
                type="email"
                name="email"
                required
                className="border border-gray-500 px-4 py-2 focus:outline-none focus:border-blue-500 col-span-2 w-full"
                placeholder="Email"
              />
            </div>
            <div className="my-3">
              <input
                type="tel"
                name="phone"
                className="border border-gray-500 px-4 py-2 focus:outline-none focus:border-blue-500 col-span-2 w-full"
                placeholder="Phone"
              />
            </div>
            <div className="flex flex-col">
              <textarea
                cols="10"
                rows="5"
                name="message"
                required
                className="border border-gray-500 px-4 py-2 focus:outline-none focus:border-blue-500 col-span-2"
                placeholder="Write your message..."
              ></textarea>
            </div>
          </div>
          <input
            type="submit"
            value="Send Message"
            className="focus:outline-none mt-5 bg-blue-500 px-4 py-2 text-white font-bold w-full cursor-pointer"
          />
        </form>
      </div>
    </div>
  );
};

export default ContactUs;
