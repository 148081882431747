import React from "react";
import { Navigate, useLocation } from "react-router-dom";

const NavigatePage = () => {
  const location = useLocation();
  // const id = useParams()?.id;
  const newsId = useLocation()?.search?.split("id=")[1];
  const id = newsId?.split("&fb")[0];

  return <Navigate to={`/news/${id}`} state={{ from: location }} replace />;
};

export default NavigatePage;
