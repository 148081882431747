import axios from "axios";
import moment from "moment";
import React, { useContext, useRef, useState } from "react";
import { Toaster, toast } from "react-hot-toast";
import SetDataInDB from "../../Component/SetDataInDB";
import getCookie from "../../Component/getCookie";
import { AuthContext } from "../../Context/AuthProvider";
import AllImages from "../Dashboard/AllImages";
import { uploadImages } from "./components/uploadImages";
import TextEditor from "./components/TextEditor";

const AddNews = () => {
  const { logOut } = useContext(AuthContext);
  const [checkLead, setCheckLead] = useState(false);
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [imageItem, setImageItem] = useState(0);
  const [uploadImg1, setUploadedImg1] = useState("");
  const [browsedFiles, setBrowsedFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [image, setImage] = useState(uploadImg1);
  const [imgModal, setImgModal] = useState(false);
  const fileInputRef = useRef(null);
  const formRef = useRef(null);
  const time = moment();
  const postTime = time._d;
  const times = postTime.toString().split(" ")[4];
  const [formData, setFormData] = useState({
    title: "",
    subTitle: "",
    img: "",
    category: "",
    postTime,
    times,
    postBy: "",
    des: "",
  });

  // const descriptionText = inputValue.replace(/\n/g, "<enter/>");

  const cookie = getCookie("khoboraccess_token");

  //api call
  const newPost = (newsData) => {
    axios
      .post(`${process.env.REACT_APP_URL}/addNews`, newsData, {
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      })
      .then((res) => {
        setLoading(false);
        toast.success("Post success");
        setDescription("");
        setImage("");
        setUploadedImg1("");
        formRef.current.reset();
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.message);
        console.error(error);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    //Call image Api and setData
    SetDataInDB({
      newPost,
      formData,
      image,
      videoUrl,
      times,
      description,
      checkLead,
      setCheckLead,
      formRef,
    });
  };

  const handleChange = (event) => {
    if (!cookie) {
      logOut()
        .then(() => {
          document.cookie = `khoboraccess_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
        })
        .catch((err) => console.error(err));
    }
    const { name, value } = event.target;

    setFormData({ ...formData, [name]: value });
  };

  return (
    <div>
      <Toaster />
      <div className="container max-w-full mx-auto md:py-16 md:px-6">
        <div className="max-w-lg mx-auto md:px-6">
          <div className="relative flex flex-wrap">
            <div className="w-full relative my-20 md:my-0 ml-5 md:ml-0 mr-4 md:mr-0">
              <div className="md:mt-6">
                <div className="text-center text-3xl font-semibold text-black">
                  New Post
                </div>

                <form
                  ref={formRef}
                  onSubmit={handleSubmit}
                  className="mt-8"
                  x-data="{password: '',password_confirm: ''}"
                >
                  <div className="mx-auto max-w-lg ">
                    <div className="py-1">
                      <span className="px-1 text-sm text-gray-600">Title</span>
                      <textarea
                        rows="2"
                        required
                        onChange={handleChange}
                        name="title"
                        type="text"
                        className="text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none"
                      />
                    </div>
                    <div className="py-1">
                      <span className="px-1 text-sm text-gray-600">
                        Sub Title
                      </span>
                      <textarea
                        rows="2"
                        onChange={handleChange}
                        name="subTitle"
                        type="text"
                        className="text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none"
                      />
                    </div>

                    <div className="py-1">
                      <span className="px-1 text-sm text-gray-600">
                        Author name
                      </span>
                      <input
                        onChange={handleChange}
                        name="postBy"
                        type="text"
                        className="text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none"
                      />
                    </div>
                    <div className="py-1 mt-1">
                      <p className="px-1 text-sm text-gray-600">Banner Image</p>
                      <input
                        id="directImg"
                        onChange={() => setUploadedImg1("")}
                        name="directImg"
                        type="checkbox"
                        className="my-2"
                        checked={!uploadImg1}
                      />

                      <label
                        className="px-1 text-sm text-black font-semibold ml-2 mr-5"
                        htmlFor="directImg"
                      >
                        Direct Image
                      </label>
                      <input
                        id="imageLink"
                        onClick={() => {
                          setImgModal(true);
                          setImageItem(1);
                        }}
                        name="imageLink"
                        type="checkbox"
                        className="my-2 cursor-pointer"
                        checked={uploadImg1}
                      />
                      <label
                        className="px-1 text-sm text-black font-semibold ml-2 cursor-pointer"
                        htmlFor="imageLink"
                      >
                        Uploaded Images
                      </label>
                    </div>

                    {!uploadImg1 ? (
                      <div className="py-1">
                        <input
                          required
                          onChange={(e) => setImage(e.target.files[0])}
                          name="img"
                          type="file"
                          className="text-md block px-3 py-2 w-full"
                        />
                      </div>
                    ) : (
                      <div className="py-1">
                        <img
                          className="w-[100px] aspect-[5/3] rounded-md"
                          src={uploadImg1}
                          alt="img2"
                        />
                      </div>
                    )}

                    <div className="py-1">
                      <span className="px-1 text-sm text-gray-600">
                        Video Url
                      </span>
                      <input
                        onChange={(e) => setVideoUrl(e.target.value)}
                        name="videoUrl"
                        type="text"
                        className="text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none"
                      />
                    </div>

                    <div className="py-1">
                      <p className="px-1 text-sm text-black font-bold">
                        Category
                      </p>
                      <select
                        required={formData.category ? false : true}
                        name="category"
                        className="text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none"
                        value={formData.category}
                        onChange={handleChange}
                      >
                        <option value="">Select One</option>
                        <option value="কোম্পানীগঞ্জ">কোম্পানীগঞ্জ</option>
                        <option value="সিলেট">সিলেট</option>
                        <option value="জাতীয়">জাতীয়</option>
                        <option value="আন্তর্জাতিক">আন্তর্জাতিক</option>
                        <option value="খেলা">খেলা</option>
                        <option value="স্বাস্থ্য">স্বাস্থ্য</option>
                        <option value="বাণিজ্য">বাণিজ্য</option>
                        <option value="বিনোদন">বিনোদন</option>
                        <option value="সাহিত্য">সাহিত্য</option>
                        <option value="শিক্ষা">শিক্ষা</option>
                        <option value="প্রযুক্তি">প্রযুক্তি</option>
                        <option value="ধর্ম">ধর্ম</option>
                        <option value="ভ্রমণ">ভ্রমণ</option>
                        <option value="মতামত">মতামত</option>
                      </select>
                    </div>

                    <div className="my-2">
                      <input
                        id="leadnews"
                        onChange={() => setCheckLead(!checkLead)}
                        name="leadNews"
                        type="checkbox"
                        className="my-2"
                      />
                      <label
                        className="px-1 text-sm text-black font-semibold ml-2"
                        htmlFor="leadnews"
                      >
                        Lead News
                      </label>
                    </div>

                    <div className="my-2 flex justify-between items-center">
                      <div className=" text-white w-[70%]">
                        <p className="p-2 bg-gray-500 hover:bg-gray-600 rounded-md text-[10px]">
                          Upload Multiple files
                          <input
                            multiple
                            type="file"
                            disabled={uploading}
                            onChange={(e) => setBrowsedFiles(e.target.files)}
                            ref={fileInputRef}
                            className="block"
                          />
                        </p>
                        <p
                          onClick={() =>
                            uploadImages(
                              browsedFiles,
                              setUploading,
                              setBrowsedFiles,
                              fileInputRef
                            )
                          }
                          disabled={uploading || !browsedFiles?.length}
                          className={`p-2 text-center bg-green-500 hover:bg-green-600 text-white rounded-sm text-[10px] mt-1 ${
                            uploading || !browsedFiles?.length
                              ? "opacity-70 cursor-not-allowed"
                              : "cursor-pointer"
                          }`}
                        >
                          {uploading ? "Uploading..." : "Upload"}
                        </p>
                      </div>
                      <div
                        onClick={() => {
                          setImgModal(true);
                          setImageItem(2);
                        }}
                        className="p-2 text-center cursor-pointer bg-gray-500 hover:bg-gray-600 text-white rounded-md text-[10px]"
                      >
                        Browse Images
                      </div>
                    </div>

                    <div className="py-1">
                      <label
                        id=""
                        className="px-1 text-sm text-gray-600 ml-2 flex justify-between"
                      >
                        <span>Description</span>
                      </label>

                      <TextEditor
                        description={description}
                        setDescription={setDescription}
                      />
                    </div>

                    <button
                      disabled={loading}
                      type="submit"
                      className="mt-3 text-lg font-semibold bg-gray-800 w-full text-white rounded-lg px-6 py-3 block shadow-xl hover:text-white hover:bg-black"
                    >
                      {loading ? (
                        <p className="w-7 h-7 border-4 border-dashed rounded-full animate-spin mx-auto"></p>
                      ) : (
                        "Post"
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {imgModal && (
        <AllImages
          imageItem={imageItem}
          setImgModal={setImgModal}
          setUploadedImg1={setUploadedImg1}
          setImage={setImage}
        />
      )}
    </div>
  );
};

export default AddNews;
