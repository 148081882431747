import React, { useEffect, useState } from "react";
import { FaFacebook, FaHome, FaRegCalendar } from "react-icons/fa";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { useContext } from "react";
import GetDate from "../../Component/GetDate";
import { AuthContext } from "../../Context/AuthProvider";
import logo from "../../Images/khoborLogo.png";
import axios from "axios";

const Navbar = () => {
  const [isFixed, setIsFixed] = useState(false);
  const [search, setSearch] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const url = useLocation().pathname?.includes("dashboard");

  const {
    closeSideBar,
    openSideBar,
    isOpen,
    bangaliDate,
    arabicDate,
    setLoading,
    setScrollY,
    user,
  } = useContext(AuthContext);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition >= 100) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isFixed, user]);

  const handleSearch = (e) => {
    e.preventDefault();
    axios
      .get(`${process.env.REACT_APP_URL}/search?title=${searchTerm}`)
      .then((response) => {
        setSearchData(response);
        setSearch(false);
      })
      .catch((error) => {
        console.error("Failed to fetch search results", error);
      });
  };

  useEffect(() => {
    if (searchData?.status) {
      navigate("/search-results", { state: searchData?.data });
    }
  }, [searchData, navigate]);

  return (
    <div style={{ fontFamily: "Oswald" }}>
      <div className="fixed top-0 z-40 w-full md:hidden bg-white">
        <div
          className={`px-2 justify-center pt-2 ${isFixed ? "hidden" : "flex"}`}
        >
          <div className="text-xs flex gap-1">
            <FaRegCalendar className="ml-1" />
            <span className="text-center">
              <GetDate /> | {bangaliDate?.bnDy} {bangaliDate?.bnMn}{" "}
              {bangaliDate?.bnYr} | {arabicDate?.arbDy} {arabicDate?.arbMn}{" "}
              {arabicDate?.arbYr}
            </span>
          </div>
        </div>
        <div className="mx-auto px-4 py-3 border-b">
          <div className="flex items-center justify-between">
            <div
              onClick={() => {
                closeSideBar();
                setLoading(true);
                setScrollY(true);
              }}
            >
              <NavLink to="/" className="text-lg font-bold ">
                <div className="flex items-center justify-center flex-shrink-0 w-[140px] h-60px md:w-24">
                  <img loading="lazy" className="w-full" src={logo} alt="" />
                </div>
              </NavLink>
            </div>
            <div className="flex gap-2">
              <button
                onClick={() => setSearch(!search)}
                type="submit"
                className={`flex items-center justify-center px-4 border border-gray-200 rounded-full ${
                  url ? "hidden" : "block"
                }`}
              >
                <svg
                  className="h-4 w-4 text-grey-dark"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z" />
                </svg>
              </button>
              <div className="flex md:hidden">
                <button
                  type="button"
                  className="text-gray-500 hover:text-gray-400 focus:outline-none focus:text-gray-400"
                  aria-label="toggle menu"
                >
                  {isOpen ? (
                    <svg
                      onClick={closeSideBar}
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-10 h-10 bg-gray-100 p-2 rounded-full"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  ) : (
                    <svg
                      onClick={openSideBar}
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-10 h-10 bg-gray-100 p-2 rounded-full"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4 6h16M4 12h16M4 18h16"
                      />
                    </svg>
                  )}
                </button>
              </div>
            </div>
          </div>
          {search && (
            <form onSubmit={handleSearch}>
              <input
                className="w-full p-2 bg-gray-100 mt-1 border-2"
                type="text"
                defaultValue={searchTerm}
                placeholder="Search Title"
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </form>
          )}
        </div>

        <div
          className={`md:hidden fixed z-40 h-screen bg-white w-4/5 ${
            !isOpen
              ? "-right-[2000px] duration-1000 ease-in-out"
              : "right-0 duration-500 ease-in-out"
          }`}
        >
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <NavLink
              to="/companiganj"
              onClick={() => {
                closeSideBar();
                setLoading(true);
                setScrollY(true);
              }}
              className={({ isActive }) =>
                isActive
                  ? "text-md mt-2 text-primary block px-3 py-1 rounded-md  font-medium "
                  : "block px-3 py-1 text-md mt-2 rounded-md font-medium hover:text-primary"
              }
            >
              কোম্পানীগঞ্জ
            </NavLink>
            <NavLink
              to="/sylhet"
              onClick={() => {
                closeSideBar();
                setLoading(true);
                setScrollY(true);
              }}
              className={({ isActive }) =>
                isActive
                  ? "text-md mt-2 text-primary block px-3 py-1 rounded-md  font-medium "
                  : "block px-3 py-1 text-md mt-2 rounded-md font-medium hover:text-primary"
              }
            >
              সিলেট
            </NavLink>
            <NavLink
              to="/national"
              onClick={() => {
                closeSideBar();
                setLoading(true);
                setScrollY(true);
              }}
              className={({ isActive }) =>
                isActive
                  ? "text-md mt-2 text-primary block px-3 py-1 rounded-md  font-medium "
                  : "block px-3 py-1 text-md mt-2 rounded-md font-medium hover:text-primary"
              }
            >
              জাতীয়
            </NavLink>
            <NavLink
              to="/international"
              onClick={() => {
                closeSideBar();
                setLoading(true);
                setScrollY(true);
              }}
              className={({ isActive }) =>
                isActive
                  ? "text-md mt-2 text-primary block px-3 py-1 rounded-md  font-medium "
                  : "block px-3 py-1 text-md mt-2 rounded-md font-medium hover:text-primary"
              }
            >
              আন্তর্জাতিক
            </NavLink>
            <NavLink
              to="/sports"
              onClick={() => {
                closeSideBar();
                setLoading(true);
                setScrollY(true);
              }}
              className={({ isActive }) =>
                isActive
                  ? "text-md mt-2 text-primary block px-3 py-1 rounded-md  font-medium "
                  : "block px-3 py-1 text-md mt-2 rounded-md font-medium hover:text-primary"
              }
            >
              খেলা
            </NavLink>
            <NavLink
              to="/health"
              onClick={() => {
                closeSideBar();
                setLoading(true);
                setScrollY(true);
              }}
              className={({ isActive }) =>
                isActive
                  ? "text-md mt-2 text-primary block px-3 py-1 rounded-md  font-medium "
                  : "block px-3 py-1 text-md mt-2 rounded-md font-medium hover:text-primary"
              }
            >
              স্বাস্থ্য
            </NavLink>
            <NavLink
              to="/trade"
              onClick={() => {
                closeSideBar();
                setLoading(true);
                setScrollY(true);
              }}
              className={({ isActive }) =>
                isActive
                  ? "text-md mt-2 text-primary block px-3 py-1 rounded-md  font-medium "
                  : "block px-3 py-1 text-md mt-2 rounded-md font-medium hover:text-primary"
              }
            >
              বাণিজ্য
            </NavLink>
            <NavLink
              to="/entertainment"
              onClick={() => {
                closeSideBar();
                setLoading(true);
                setScrollY(true);
              }}
              className={({ isActive }) =>
                isActive
                  ? "text-md mt-2 text-primary block px-3 py-1 rounded-md  font-medium "
                  : "block px-3 py-1 text-md mt-2 rounded-md font-medium hover:text-primary"
              }
            >
              বিনোদন
            </NavLink>
            <NavLink
              to="/literature"
              onClick={() => {
                closeSideBar();
                setLoading(true);
                setScrollY(true);
              }}
              className={({ isActive }) =>
                isActive
                  ? "text-md mt-2 text-primary block px-3 py-1 rounded-md  font-medium "
                  : "block px-3 py-1 text-md mt-2 rounded-md font-medium hover:text-primary"
              }
            >
              সাহিত্য
            </NavLink>
            <NavLink
              to="/education"
              onClick={() => {
                closeSideBar();
                setLoading(true);
                setScrollY(true);
              }}
              className={({ isActive }) =>
                isActive
                  ? "text-md mt-2 text-primary block px-3 py-1 rounded-md  font-medium "
                  : "block px-3 py-1 text-md mt-2 rounded-md font-medium hover:text-primary"
              }
            >
              শিক্ষা
            </NavLink>
            <NavLink
              to="/technology"
              onClick={() => {
                closeSideBar();
                setLoading(true);
                setScrollY(true);
              }}
              className={({ isActive }) =>
                isActive
                  ? "text-md mt-2 text-primary block px-3 py-1 rounded-md  font-medium "
                  : "block px-3 py-1 text-md mt-2 rounded-md font-medium hover:text-primary"
              }
            >
              প্রযুক্তি
            </NavLink>
            <NavLink
              to="/religion"
              onClick={() => {
                closeSideBar();
                setLoading(true);
                setScrollY(true);
              }}
              className={({ isActive }) =>
                isActive
                  ? "text-md mt-2 text-primary block px-3 py-1 rounded-md  font-medium "
                  : "block px-3 py-1 text-md mt-2 rounded-md font-medium hover:text-primary"
              }
            >
              ধর্ম
            </NavLink>
            <NavLink
              to="/traveling"
              onClick={() => {
                closeSideBar();
                setLoading(true);
                setScrollY(true);
              }}
              className={({ isActive }) =>
                isActive
                  ? "text-md mt-2 text-primary block px-3 py-1 rounded-md  font-medium "
                  : "block px-3 py-1 text-md mt-2 rounded-md font-medium hover:text-primary"
              }
            >
              ভ্রমণ
            </NavLink>
            <NavLink
              to="/opinion"
              onClick={() => {
                closeSideBar();
                setLoading(true);
                setScrollY(true);
              }}
              className={({ isActive }) =>
                isActive
                  ? "text-md mt-2 text-primary block px-3 py-1 rounded-md  font-medium "
                  : "block px-3 py-1 text-md mt-2 rounded-md font-medium hover:text-primary"
              }
            >
              মতামত
            </NavLink>
            <NavLink
              to="/allNews"
              onClick={() => {
                closeSideBar();
                setLoading(true);
                setScrollY(true);
              }}
              className={({ isActive }) =>
                isActive
                  ? "text-md my-2 text-primary block px-3 pt-1 pb-2 rounded-md  font-medium border-b border-black"
                  : "block px-3 pt-1 pb-2 text-md my-2 rounded-md font-medium hover:text-primary border-b border-black"
              }
            >
              সব খবর
            </NavLink>
            <div className="flex justify-center ">
              <div>
                <p className="font-bold text-md mb-2">Follow Us On</p>
                <div className="flex gap-3">
                  <a
                    className="mx-auto"
                    href="https://www.facebook.com/ajkerkhobor2019"
                  >
                    <FaFacebook className="w-6 h-6 text-blue-600" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`w-full hidden md:block max-w-[1500px] ${
          !isFixed ? "mx-auto" : ""
        }`}
      >
        <div className="h-9 bg-white relative pt-2">
          <div className=" top-[25%] ml-4">
            <div className="flex justify-between items-center">
              <Link to="/" onClick={() => setLoading(true)}>
                <img
                  className="w-52"
                  // src="https://i.ibb.co/1r1QFJm/newsLogo.png"
                  src={logo}
                  alt=""
                />
              </Link>
              <p className="text-xs flex gap-1">
                <FaRegCalendar className="ml-1" /> <GetDate /> |{" "}
                {bangaliDate?.bnDy} {bangaliDate?.bnMn} {bangaliDate?.bnYr} |{" "}
                {arabicDate?.arbDy} {arabicDate?.arbMn} {arabicDate?.arbYr}
              </p>
            </div>
          </div>
        </div>

        <div
          className={`w-full bg-white ${
            isFixed ? "fixed duration-500 ease-in-out top-0 z-40" : ""
          }`}
        >
          <div className={`items-center flex px-8 h-[60px] border-b`}>
            <div className="flex justify-between w-full">
              <div className="flex-1 justify-self-center pb-3  md:block md:pb-0 md:mt-0">
                <div className="items-center justify-center space-y-8 md:flex md:space-x-6 md:space-y-0 flex-wrap">
                  <NavLink onClick={() => setLoading(true)} className="" to="/">
                    <FaHome className="w-6 h-6" />
                  </NavLink>
                  <NavLink
                    onClick={() => {
                      setLoading(true);
                      setScrollY(true);
                    }}
                    className={({ isActive }) =>
                      isActive
                        ? "text-sm border-b-2 border-black"
                        : "text-sm hover:text-primary"
                    }
                    to="/companiganj"
                    style={{ lineHeight: "19px" }}
                  >
                    কোম্পানীগঞ্জ
                  </NavLink>
                  <NavLink
                    onClick={() => {
                      setLoading(true);
                      setScrollY(true);
                    }}
                    className={({ isActive }) =>
                      isActive
                        ? "text-sm border-b-2 border-black"
                        : "text-sm hover:text-primary"
                    }
                    to="/sylhet"
                    style={{ lineHeight: "19px" }}
                  >
                    সিলেট
                  </NavLink>
                  <NavLink
                    onClick={() => {
                      setLoading(true);
                      setScrollY(true);
                    }}
                    className={({ isActive }) =>
                      isActive
                        ? "text-sm border-b-2 border-black"
                        : "text-sm hover:text-primary"
                    }
                    to="/national"
                    style={{ lineHeight: "19px" }}
                  >
                    জাতীয়
                  </NavLink>
                  <NavLink
                    onClick={() => {
                      setLoading(true);
                      setScrollY(true);
                    }}
                    className={({ isActive }) =>
                      isActive
                        ? "text-sm border-b-2 border-black"
                        : "text-sm hover:text-primary"
                    }
                    to="/international"
                    style={{ lineHeight: "19px" }}
                  >
                    আন্তর্জাতিক
                  </NavLink>
                  <NavLink
                    onClick={() => {
                      setLoading(true);
                      setScrollY(true);
                    }}
                    className={({ isActive }) =>
                      isActive
                        ? "text-sm border-b-2 border-black"
                        : "text-sm hover:text-primary"
                    }
                    to="/sports"
                    style={{ lineHeight: "19px" }}
                  >
                    খেলা
                  </NavLink>
                  <NavLink
                    onClick={() => {
                      setLoading(true);
                      setScrollY(true);
                    }}
                    className={({ isActive }) =>
                      isActive
                        ? "text-sm border-b-2 border-black"
                        : "text-sm hover:text-primary"
                    }
                    to="/health"
                    style={{ lineHeight: "19px" }}
                  >
                    স্বাস্থ্য
                  </NavLink>
                  <NavLink
                    onClick={() => {
                      setLoading(true);
                      setScrollY(true);
                    }}
                    className={({ isActive }) =>
                      isActive
                        ? "text-sm border-b-2 border-black"
                        : "text-sm hover:text-primary"
                    }
                    to="/trade"
                    style={{ lineHeight: "19px" }}
                  >
                    বাণিজ্য
                  </NavLink>
                  <NavLink
                    onClick={() => {
                      setLoading(true);
                      setScrollY(true);
                    }}
                    className={({ isActive }) =>
                      isActive
                        ? "text-sm border-b-2 border-black"
                        : "text-sm hover:text-primary"
                    }
                    to="/entertainment"
                    style={{ lineHeight: "19px" }}
                  >
                    বিনোদন
                  </NavLink>
                  <NavLink
                    onClick={() => {
                      setLoading(true);
                      setScrollY(true);
                    }}
                    className={({ isActive }) =>
                      isActive
                        ? "text-sm border-b-2 border-black"
                        : "text-sm hover:text-primary"
                    }
                    to="/literature"
                    style={{ lineHeight: "19px" }}
                  >
                    সাহিত্য
                  </NavLink>
                  <NavLink
                    onClick={() => {
                      setLoading(true);
                      setScrollY(true);
                    }}
                    className={({ isActive }) =>
                      isActive
                        ? "text-sm border-b-2 border-black"
                        : "text-sm hover:text-primary"
                    }
                    to="/education"
                    style={{ lineHeight: "19px" }}
                  >
                    শিক্ষা
                  </NavLink>
                  <NavLink
                    onClick={() => {
                      setLoading(true);
                      setScrollY(true);
                    }}
                    className={({ isActive }) =>
                      isActive
                        ? "text-sm border-b-2 border-black"
                        : "text-sm hover:text-primary"
                    }
                    to="/technology"
                    style={{ lineHeight: "19px" }}
                  >
                    প্রযুক্তি
                  </NavLink>

                  <NavLink
                    onClick={() => {
                      setLoading(true);
                      setScrollY(true);
                    }}
                    className={({ isActive }) =>
                      isActive
                        ? "text-sm border-b-2 border-black"
                        : "text-sm hover:text-primary"
                    }
                    to="/religion"
                    style={{ lineHeight: "19px" }}
                  >
                    ধর্ম
                  </NavLink>
                  <NavLink
                    onClick={() => {
                      setLoading(true);
                      setScrollY(true);
                    }}
                    className={({ isActive }) =>
                      isActive
                        ? "text-sm border-b-2 border-black"
                        : "text-sm hover:text-primary"
                    }
                    to="/traveling"
                    style={{ lineHeight: "19px" }}
                  >
                    ভ্রমণ
                  </NavLink>
                  <NavLink
                    state={0}
                    onClick={() => {
                      setLoading(true);
                      setScrollY(true);
                    }}
                    className={({ isActive }) =>
                      isActive
                        ? "text-sm border-b-2 border-black"
                        : "text-sm hover:text-primary"
                    }
                    to="/opinion"
                    style={{ lineHeight: "19px" }}
                  >
                    মতামত
                  </NavLink>
                  <NavLink
                    state={0}
                    onClick={() => {
                      setLoading(true);
                      setScrollY(true);
                    }}
                    className={({ isActive }) =>
                      isActive
                        ? "text-sm border-b-2 border-black"
                        : "text-sm hover:text-primary"
                    }
                    to="/allNews"
                    style={{ lineHeight: "19px" }}
                  >
                    সব খবর
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
