import axios from "axios";
import React from "react";
import { Link } from "react-router-dom";
import getCookie from "../../Component/getCookie";
import toast from "react-hot-toast";

const DashboardNewsForLg = ({
  data,
  setDeleteModal,
  setEditModal,
  setEditData,
  setDeleteId,
  handleLoad,
  setLoading,
}) => {
  const cookie = getCookie("khoboraccess_token");

  const handleHideToggle = async (id) => {
    setLoading(true);

    try {
      const res = await axios.patch(
        `${process.env.REACT_APP_URL}/hide-news/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${cookie}`,
          },
        }
      );
      if (res?.status === 200) {
        handleLoad();
        setLoading(false);
        toast.success("Success");
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col">
      {data?.length ? (
        <div className="overflow-x-auto sm:mx-0.5 lg:mx-0.5">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden">
              <table className="min-w-full">
                <thead className="bg-black text-white font-bold border-b">
                  <tr>
                    <th
                      scope="col"
                      className="text-sm font-medium px-6 py-4 text-left"
                    >
                      #
                    </th>
                    <th
                      scope="col"
                      className="text-sm font-medium px-6 py-4 text-left"
                    >
                      Image
                    </th>
                    <th
                      scope="col"
                      className="text-sm font-medium px-6 py-4 text-left"
                    >
                      Author
                    </th>
                    <th
                      scope="col"
                      className="text-sm font-medium px-6 py-4 text-left"
                    >
                      Category
                    </th>
                    <th
                      scope="col"
                      className="text-sm font-medium px-6 py-4 text-left"
                    >
                      Title
                    </th>
                    <th
                      scope="col"
                      className="text-sm font-medium px-6 py-4 text-left"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((d, i) => (
                    <tr
                      className="bg-gray-100 border-b border-gray-500 mb-2"
                      key={d?._id}
                    >
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {i + 1}
                      </td>
                      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        <img
                          className="w-12 h-12 rounded-md"
                          src={d?.img}
                          alt=""
                        />
                      </td>
                      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        {d?.postBy}
                      </td>
                      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        {d?.category}
                      </td>
                      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-pre-wrap break-words">
                        <Link
                          className="hover:text-blue-500"
                          to={`/news/${d?._id}`}
                        >
                          {d?.title}
                        </Link>
                      </td>
                      <td>
                        <div className="flex gap-3 mr-2 md:flex-wrap lg:flex-nowrap">
                          <button
                            onClick={() => {
                              setEditModal(true);
                              setEditData(d);
                            }}
                            className="bg-blue-500 px-3 py-1 rounded-md text-white text-xs"
                          >
                            Edit
                          </button>
                          <button
                            onClick={() => {
                              setDeleteModal(true);
                              setDeleteId(d?._id);
                            }}
                            className="bg-red-500 px-3 py-1 text-white rounded-md text-xs"
                          >
                            Delete
                          </button>
                          <button
                            onClick={() => {
                              handleHideToggle(d?._id);
                            }}
                            className={`flex gap-1 justify-center items-center px-3 py-1 text-white rounded-md text-xs ${
                              d?.hide ? "bg-red-500" : "bg-green-500"
                            }`}
                          >
                            {d?.hide ? (
                              <>
                                <span>Hidden</span> <span>🔒</span>
                              </>
                            ) : (
                              <>
                                <span>Hide</span> <span>🌎</span>
                              </>
                            )}
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="flex justify-center my-4">
            {data?.length > 9 && (
              <button
                onClick={handleLoad}
                className="bg-gray-600 text-white rounded-md px-4 py-2 font-bold"
              >
                আরও
              </button>
            )}
          </div>
        </div>
      ) : (
        <h2 className="text-2xl text-center my-4">No Results Found</h2>
      )}
    </div>
  );
};

export default DashboardNewsForLg;
