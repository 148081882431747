import { createBrowserRouter } from "react-router-dom";
import Main from "../Layouts/Main";
import Home from "../Pages/Home/Home";
import NewsCategory from "../Pages/NewsCategory/NewsCategory";
import NewsDetails from "../Pages/NewsDetails/NewsDetails";
import AddNews from "../Pages/AddNews/AddNews";
import Dashboard from "../Pages/Dashboard/Dashboard";
import EditPost from "../Pages/EditPost/EditPost";
import Login from "../Pages/Login/Login";
import ErrorPage from "../Pages/ErrorPage/ErrorPage";
import AdminRoutes from "./AdminRoutes";
import PrivateRoutes from "./PrivateRoutes";
import NewsList from "../Pages/Dashboard/NewsList";
import KormokortaBrindo from "../Pages/KormokortaBrindo/KormokortaBrindo";
import NavigatePage from "../Pages/NavigatePage";
import ContactUs from "../Pages/ContactUs/ContactUs";
import SearchResults from "../Pages/SearchResults/SearchResults";
import PrivacyPolicy from "../Pages/PrivacyPolicy/PrivacyPolicy";
import AllImages from "../Pages/Dashboard/AllImages";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/not-found",
        element: <ErrorPage />,
      },
      {
        path: "/companiganj",
        element: <NewsCategory />,
      },
      {
        path: "/sylhet",
        element: <NewsCategory />,
      },
      {
        path: "/national",
        element: <NewsCategory />,
      },
      {
        path: "/international",
        element: <NewsCategory />,
      },
      {
        path: "/sports",
        element: <NewsCategory />,
      },
      {
        path: "/health",
        element: <NewsCategory />,
      },
      {
        path: "/trade",
        element: <NewsCategory />,
      },
      {
        path: "/entertainment",
        element: <NewsCategory />,
      },
      {
        path: "/literature",
        element: <NewsCategory />,
      },
      {
        path: "/education",
        element: <NewsCategory />,
      },
      {
        path: "/technology",
        element: <NewsCategory />,
      },
      {
        path: "/religion",
        element: <NewsCategory />,
      },
      {
        path: "/traveling",
        element: <NewsCategory />,
      },
      {
        path: "/search-results",
        element: <SearchResults />,
      },
      {
        path: "/opinion",
        element: <NewsCategory />,
      },
      {
        path: "/allNews",
        element: <NewsCategory />,
      },
      {
        path: "/Officials",
        element: <KormokortaBrindo />,
      },
      {
        path: "/news/:id",
        element: <NewsDetails />,
      },
      {
        path: "/api/newspreview",
        element: <NavigatePage />,
      },
      {
        path: "/contact-us",
        element: <ContactUs />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/privacy-policy",
        element: <PrivacyPolicy />,
      },
    ],
  },

  {
    path: "/dashboard",
    element: (
      <PrivateRoutes>
        <Dashboard />
      </PrivateRoutes>
    ),
    children: [
      {
        path: "/dashboard/addNews",
        element: (
          <AdminRoutes>
            <AddNews />
          </AdminRoutes>
        ),
      },
      {
        path: "/dashboard/editpost",
        element: (
          <AdminRoutes>
            <EditPost />
          </AdminRoutes>
        ),
      },
      {
        path: "/dashboard/newsList",
        element: (
          <AdminRoutes>
            <NewsList />
          </AdminRoutes>
        ),
      },
      {
        path: "/dashboard/all-Images",
        element: (
          <AdminRoutes>
            <AllImages />
          </AdminRoutes>
        ),
      },
      {
        path: "/dashboard",
        element: (
          <AdminRoutes>
            <NewsList />
          </AdminRoutes>
        ),
      },
    ],
  },
]);

export default router;
