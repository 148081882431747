import React, { useContext } from "react";
import Marquee from "react-fast-marquee";
import { AuthContext } from "../Context/AuthProvider";
import { useNavigate } from "react-router-dom";

const NewsScroller = () => {
  const { data } = useContext(AuthContext);
  const newData = data.slice(0, 5);
  const navigate = useNavigate();

  const handleClick = (data) => {
    navigate(`/news/${data?._id}`, { state: data });
  };

  return (
    <>
      {data?.length ? (
        <div className="relative">
          <p className="absolute bg-blue-700 text-white text-md z-10 font-bold py-[3px] px-4 h-full pt-[4px]">
            সর্বশেষ
          </p>
          <Marquee
            className="bg-blue-950 text-white p-1 flex gap-5"
            pauseOnHover={true}
          >
            {newData?.map((d, index) => (
              <p
                onClick={() => handleClick(d)}
                className="mr-10 cursor-pointer"
                key={index}
              >
                {d?.title}
              </p>
            ))}
          </Marquee>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default NewsScroller;
