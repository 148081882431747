import React from "react";
import { Link, useLocation } from "react-router-dom";

const SearchResults = () => {
  const newsData = useLocation().state;

  return (
    <div className="my-5 min-h-[50vh]">
      <h1 className="text-xl font-bold text-center mb-4">
        {newsData[0]?.title ? "Search Results" : "No Result Found"}
      </h1>
      <div className="md:w-[50%]">
        {newsData?.map((d, index) => {
          return (
            <Link
              to={`/news/${d?._id}`}
              state={d}
              key={index}
              className="flex w-full items-center gap-2 border p-1 rounded-md mb-2"
            >
              <img
                className="w-[108px] h-20 rounded-md"
                src={d?.img}
                alt="img"
                loading="lazy"
              />
              <p className="text-md md:text-lg font-bold">{d?.title}</p>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default SearchResults;
