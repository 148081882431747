import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { FaClock, FaCopy } from "react-icons/fa";
import NewsTabs from "../../Component/NewsTabs";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getTimeAgo } from "../../Component/GetTimeAgo";
import axios from "axios";
import CopyUrl from "../../Component/CopyUrl";
import { Toaster } from "react-hot-toast";
import VideoPlayer from "../../Component/VideoPlayer";
import { AuthContext } from "../../Context/AuthProvider";

const NewsDetails = () => {
  window.scrollTo(0, 0);
  const { data } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [othersNews, setOtherNews] = useState([]);
  const [newsContent, setNewsContent] = useState();
  const id = useParams()?.id;
  const time = newsContent?.postTime?.split("T")[0];
  const day = time?.split("-")[2];
  const month = time?.split("-")[1];
  const year = time?.split("-")[0];
  const currentUrl = `${process.env.REACT_APP_URL}/newspreview?id=${id}`;


  const time24h = newsContent?.times ? newsContent.times : "12:23:32";
  const [hours, minutes, seconds] = time24h?.split(":");
  const date = new Date();
  date.setHours(hours, minutes, seconds);

  const time12h = date.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  });

  useEffect(() => {
    const details = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/newsDetails`,
          { params: { id: id } }
        );
        const dt = response.data;

        if (dt?.error) {
          navigate("/not-found");
          return;
        }

        setNewsContent(dt);
        setLoading(true);
      } catch (error) {
        console.error("Error fetching news titles:", error);
      }
    };
    details();

    if (loading) {
      const fetchLeadNews = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_URL}/category`,
            { params: { number: 6, category: newsContent?.category } }
          );
          const dt = response.data;
          setOtherNews(dt?.filter((n) => n?._id !== id));
          setLoading(true);
        } catch (error) {
          console.error("Error fetching news titles:", error);
        }
      };

      fetchLeadNews();
    }
  }, [loading, id]); //eslint-disable-line

  //user visit post
  axios
    .get("https://api.ipify.org?format=json")
    .then((res) => {
      const userIp = res?.data?.ip;
      const newsId = newsContent?._id;

      axios
        .put(`${process.env.REACT_APP_URL}/visitPost`, { userIp, newsId })
        .then((res) => {})
        .catch((e) => console.log(e));
    })
    .catch((e) => console.log(e));

  if (!newsContent?._id) {
    return (
      <div className="md:full">
        {!data?.length ? (
          <>
            <div className="w-full h-[32px] skeleton-placeholder"></div>
            <div className="w-[88px] h-[25px] skeleton-placeholder"></div>
          </>
        ) : (
          ""
        )}
        <div className="md:flex gap-5 mt-3">
          <div className="w-full mt-2">
            <div className="w-full h-[50px] md:h-[60px] mt-2 skeleton-placeholder"></div>
            <div className="w-full h-[200px] md:h-[350px] mt-2 skeleton-placeholder"></div>
            <div className="my-4 w-full mx-auto">
              <div className="w-full h-[8px] mt-2 skeleton-placeholder"></div>
              <div className="w-full h-[8px] mt-2 skeleton-placeholder"></div>
              <div className="w-[70%] h-[8px] mt-2 skeleton-placeholder"></div>
              <div className="w-full h-[8px] mt-2 skeleton-placeholder"></div>
              <div className="w-full h-[8px] mt-2 skeleton-placeholder"></div>
              <div className="w-[70%] h-[8px] mt-2 skeleton-placeholder"></div>
            </div>
          </div>
          <div className="w-[60%] h-[200px] hidden md:block md:h-[500px] md:mt-10 skeleton-placeholder"></div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Helmet>
        <title>{newsContent?.title}</title>
        <meta property="og:title" content={newsContent?.title} />
        <meta property="og:image" content={newsContent?.img} />
        <meta property="og:url" content={currentUrl} />
      </Helmet>
      <div className="my-10 lg:w-[80%] mx-auto flex flex-col md:flex-row gap-8">
        <Toaster />
        <div className="md:w-[90%]">
          <div className="mb-4 ">
            <span className="text-sm font-bold border-b-2 border-gray-800 text-red-500">
              {newsContent?.category}
            </span>
          </div>
          <h1 className="text-2xl md:text-3xl font-bold">
            {newsContent?.title}
          </h1>
          <h3 className="text-xl md:text-xl my-4">{newsContent?.subTitle}</h3>
          <div className="w-full max-w-[780px] min-h-[160px] bg-gray-300 overflow-hidden rounded-md md:min-h-[250px] md:bg-gray-300">
            {newsContent?.video ? (
              VideoPlayer(newsContent?.video)
            ) : (
              <img
                src={newsContent?.img}
                alt=""
                className="rounded-md w-full"
              />
            )}
          </div>
          <p className="text-gray-600 my-3 font-bold">{newsContent?.postBy}</p>
          <p className="text-gray-600">
            তারিখ: {`${day}-${month}-${year}`}{" "}
            <span className="ml-2">{time12h}</span>
          </p>
          <div className="flex items-center gap-4 my-3">
            <FacebookShareButton url={currentUrl}>
              <FacebookIcon size={32} round={true} />
            </FacebookShareButton>
            <WhatsappShareButton url={currentUrl}>
              <WhatsappIcon size={32} round={true} />
            </WhatsappShareButton>
            <TwitterShareButton url={currentUrl}>
              <TwitterIcon size={32} round={true} />
            </TwitterShareButton>
            <FaCopy
              onClick={() => CopyUrl(currentUrl)}
              className="w-6 h-6 text-blue-500 cursor-pointer"
            />
          </div>
          <div
            className="html-container"
            dangerouslySetInnerHTML={{ __html: `${newsContent?.des}` }}
          ></div>
        </div>
        <div className="md:mt-[69px]">
          <NewsTabs setLoading={setLoading} />
          <div className="flex">
            <div className={`mt-6 ${!othersNews?.length && "hidden"}`}>
              <p className="text-xl md:text-xl font-bold">আরও খবর</p>
              <p className="h-[4px] bg-red-600 text-center w-1/2"></p>
            </div>
          </div>
          {othersNews?.slice(0, 6)?.map((d, index) => (
            <Link
              onClick={() => setLoading(false)}
              key={index}
              to={`/news/${d?._id}`}
              state={d}
              className="flex flex-col justify-center items-center px-2 py-6 bg-gray-100 rounded-md mt-4"
            >
              <img
                className="w-20 h-20 rounded-full"
                src={d?.img}
                alt="img"
                loading="lazy"
              />
              <p className="text-gray-600 text-sm flex my-4 justify-center items-center gap-2">
                <FaClock /> {getTimeAgo(d?.postTime)}
              </p>
              <p className="text-md font-bold text-center">{d?.title}</p>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NewsDetails;
