import React from "react";

const KormokortaBrindo = () => {
  window.scrollTo(0, 0);
  return (
    <div className="min-h-screen">
      <div className="mb-10">
        <div className="bg-gradient-to-r from-cyan-500 to-blue-500">
          <h1 className="text-2xl md:text-4xl font-bold text-center text-white py-3 md:py-4">
            আমাদের কর্মকর্তাবৃন্দ
          </h1>
        </div>
      </div>
      <div className="md:max-w-xs mx-2 md:mx-auto flex flex-col gap-3">
        <p className="text-md md:text-xl font-bold p-4 text-center border-4 rounded-md shadow-md">
          সভাপতি: রুহুল আমিন বাবুল
        </p>
        <p className="text-md md:text-xl font-bold p-4 text-center border-4 rounded-md shadow-md">
          সম্পাদক: আব্দুল জলিল
        </p>
        <p className="text-md md:text-xl font-bold p-4 text-center border-4 rounded-md shadow-md">
          বার্তা সম্পাদক: লবীব আহমদ
        </p>
        <p className="text-md md:text-xl font-bold p-4 text-center border-4 rounded-md shadow-md">
          সাহিত্য সম্পাদক: কবির মাহমুদ
        </p>
        <p className="text-md md:text-xl font-bold p-4 text-center border-4 rounded-md shadow-md">
          স্টাফ রিপোর্টার: সুহেল আহমদ
        </p>
      </div>
    </div>
  );
};

export default KormokortaBrindo;
