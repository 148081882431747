import React from "react";
import { useNavigate } from "react-router-dom";

const EditModal = ({ editModal, setEditModal, editData }) => {
  const navigate = useNavigate();

  const handleEdit = (event) => {
    navigate("/dashboard/editpost", { state: editData });
  };
  return (
    <div
      className={`min-min-w-screen h-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover ${
        !editModal && "hidden"
      }`}
      id="modal-id"
    >
      <div className="absolute bg-black opacity-80 inset-0 z-0"></div>
      <div className="w-[90%] md:w-full  max-w-lg p-5 relative mx-auto my-auto rounded-xl shadow-lg  bg-white ">
        <div className="">
          <div className="text-center p-5 flex-auto justify-center">
            <h2 className="text-xl font-bold py-4 ">Edit Post?</h2>
            <p className="text-sm text-gray-500 px-8">
              Do you really want to edit your post?
            </p>
          </div>

          <div className="p-3  mt-2 text-center space-x-4 md:block">
            <button
              onClick={() => setEditModal(false)}
              className="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100"
            >
              No
            </button>
            <button
              onClick={handleEdit}
              className="mb-2 md:mb-0 bg-blue-500 border px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg hover:bg-red-600"
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditModal;
