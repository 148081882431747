import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import 'hijri-date';
import Calendar from "date-bengali-revised";
import { AuthContext } from "../Context/AuthProvider";

const bengaliNumbers = ["০", "১", "২", "৩", "৪", "৫", "৬", "৭", "৮", "৯"];

const GetDate = () => {
  const { setBangaliDate, setArabicDate} = useContext(AuthContext);
  const [date, setDate] = useState(null);


  function getFormattedDate() {
    const currentDate = moment().locale("bn").format("DD-MM-YYYY"); // Get the current date in DD-MM-YYYY format with Bengali locale
    const formattedDate = translateToBengali(currentDate); // Translate the date to Bengali
    return formattedDate;
  }

  function translateToBengali(date) {
    const bangaliDays = [
      "রবিবার",
      "সোমবার",
      "মঙ্গলবার",
      "বুধবার",
      "বৃহস্পতিবার",
      "শুক্রবার",
      "শনিবার",
    ];

    const bangaliMonths = [
      "জানুয়ারি",
      "ফেব্রুয়ারি",
      "মার্চ",
      "এপ্রিল",
      "মে",
      "জুন",
      "জুলাই",
      "আগস্ট",
      "সেপ্টেম্বর",
      "অক্টোবর",
      "নভেম্বর",
      "ডিসেম্বর",
    ];

    const bengaliMonths = [
      'বৈশাখ',
      'জ্যৈষ্ঠ',
      'আষাঢ়',
      'শ্রাবণ',
      'ভাদ্র',
      'আশ্বিন',
      'কার্তিক',
      'অগ্রহায়ণ',
      'পৌষ',
      'মাঘ',
      'ফাল্গুন',
      'চৈত্র'
    ];

    const arabicMonthNamesInBengali = [
      "মুহররম",
      "সফর",
      "রবিউল আউয়াল",
      "রবিউস সানি",
      "জমাদিউল আউয়াল",
      "জমাদিউস সানি",
      "রজব",
      "শাবান",
      "রমজান",
      "শাওয়াল",
      "জ্বিলকদ",
      "জ্বিলহজ্জ"
    ];

    const parts = date.split("-");
    const dy = parts[0];
    const mn = parts[1];
    const yr = parts[2];


    const nowGreg = new Date();
  const nowHijri = nowGreg.toHijri(); 

    const arbDy = convertToBengaliNumber(nowHijri.date);
    const arbMn = arabicMonthNamesInBengali[Number(nowHijri.month)-1];
    const arbYr = convertToBengaliNumber(nowHijri.year);
    setArabicDate({arbDy, arbMn, arbYr});



    //get bangali date
    let cal = new Calendar();
    cal.fromGregorian(yr, mn, dy);
    setBangaliDate(cal)
    const bnDy = convertToBengaliNumber(cal.day)
    const bnMn = bengaliMonths[Number(cal.month)-1]
    const bnYr = convertToBengaliNumber(cal.year)
    setBangaliDate({bnDy, bnMn, bnYr});

    // get english date
    const day = convertToBengaliNumber(parts[0]);
    const month = bangaliMonths[Number(parts[1]) - 1];
    const year = convertToBengaliNumber(parts[2]);

    const translatedDay = bangaliDays[moment(date, "DD-MM-YYYY").day()];

    const translatedDate = `${translatedDay}, ${day} ${month} ${year}`;
    setDate(translatedDate);
    return translatedDate;
  }

 
  useEffect(() => {
    getFormattedDate();
  }, [date]); //eslint-disable-line

  return (
    <>
      <span>{date}</span>
    </>
  );
};

export default GetDate;



export  function convertToBengaliNumber(number) {
  const numberString = String(number);
  let bengaliNumber = "";
  for (let i = 0; i < numberString.length; i++) {
    const digit = parseInt(numberString[i]);
    bengaliNumber += bengaliNumbers[digit];
  }

  return bengaliNumber;
}
