import React, { useContext } from "react";
import moment from "moment";
import { FaFacebook, FaTwitter, FaYoutube } from "react-icons/fa";
import footerLogo from "../../Images/ajkerkhobor.png";
import { Link } from "react-router-dom";
import { AuthContext } from "../../Context/AuthProvider";

const Footer = () => {
  const { data } = useContext(AuthContext);
  const year = moment().year();

  if (!data?.length) {
    return <div></div>;
  }

  return (
    <footer className="text-white bg-black md:flex justify-evenly">
      <div className="px-5 py-10 md:py-20 max-w-[1400px] mx-auto w-full">
        <div className="md:flex gap-32">
          <div className="mb-5 md:mb-0 flex justify-center md:block">
            <img className="w-60" src={footerLogo} alt="" />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-2 gap-2 justify-between w-full">
            <div className="flex flex-col gap-2">
              <p className="text-sm ">
                সম্পাদক মন্ডলীর সভাপতি : রুহুল আমিন বাবুল
              </p>
              <p className="text-sm ">সম্পাদক : আব্দুল জলিল</p>
              <p className="text-sm ">মোবাইল: +8801712-389 309</p>
              <p className="text-sm ">ইমেইল: ajkerkhobor19@gmail.com</p>
              <p className="text-sm ">
                সাহিত্য ইমেইল: ajkerkhoborshahitta@gmal.com
              </p>
            </div>
            <div className="flex flex-col gap-2">
              <p className="text-sm ">
                <Link to={`/Officials`}>কর্মকর্তাবৃন্দ</Link>
              </p>
              <p className="text-sm">
                <Link to="/privacy-policy">Privacy Policy</Link>
              </p>
              <p className="text-sm">
                <Link to="/contact-us">Contact Us</Link>
              </p>
              <p className="text-sm ">
                ঠিকানা: হাজী আখলু মিয়া মার্কেট, কোম্পানীগঞ্জ, সিলেট
              </p>
            </div>
          </div>
        </div>
        <hr className="my-4 md:my-10" />
        <div className="md:mt-5 text-sm flex flex-col-reverse gap-5 md:flex-row md:justify-between max-w-[1000px]">
          <p>Copyright © {year} - All right reserved by আজকের খবর</p>
          <div className="flex gap-5">
            <div className="flex gap-3">
              <a className="" href="https://www.facebook.com/ajkerkhobor2019">
                <FaFacebook className="w-5 h-5 text-gray-300" />
              </a>
            </div>
            <div className="flex gap-3">
              <a
                className=""
                href="https://www.youtube.com/channel/UCHyrzvDzMkrWvGl8J6vaA2A"
              >
                <FaYoutube className="w-5 h-5 text-gray-300" />
              </a>
            </div>
            <div className="flex gap-3">
              <a className="" href="https://www.twitter.com/">
                <FaTwitter className="w-5 h-5 text-gray-300" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
