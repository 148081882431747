import {
  createUserWithEmailAndPassword,
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import React, { createContext, useEffect, useState } from "react";
import app from "../Firebase/Firebase.Config";
import axios from "axios";
const auth = getAuth(app);
export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [scrollY, setScrollY] = useState(false);
  const [bangaliDate, setBangaliDate] = useState(null);
  const [arabicDate, setArabicDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [view, setView] = useState([]);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("সব খবর");
  const [leadNews, setLeadNews] = useState([]);
  const openSideBar = () => setIsOpen(true);
  const closeSideBar = () => setIsOpen(false);

  const signUp = (email, password) => {
    setIsLoading(false);
    return createUserWithEmailAndPassword(auth, email, password);
  };

  const signIn = (email, password) => {
    setIsLoading(false);
    return signInWithEmailAndPassword(auth, email, password);
  };

  const logOut = () => {
    setIsLoading(false);
    return signOut(auth);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setIsLoading(false);
    });
    return () => {
      unsubscribe();
    };
  }, [user, isLoading, auth]); //eslint-disable-line

  useEffect(() => {
    const latestNews = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_URL}/latest`);
        const dt = response.data;
        setData(dt);
      } catch (error) {
        console.error("Error fetching news titles:", error);
      }
    };

    const highestView = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/highestView`
        );
        const dt = response.data;
        setView(dt);
      } catch (error) {
        console.error("Error fetching news titles:", error);
      }
    };

    latestNews();
    highestView();
  }, []);

  const authInfo = {
    openSideBar,
    closeSideBar,
    isOpen,
    bangaliDate,
    setBangaliDate,
    arabicDate,
    setArabicDate,
    data,
    setData,
    loading,
    setLoading,
    scrollY,
    setScrollY,
    view,
    signUp,
    signIn,
    logOut,
    user,
    isLoading,
    value,
    setValue,
    open,
    setOpen,
    leadNews,
    setLeadNews,
  };

  return (
    <AuthContext.Provider value={authInfo}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;
