import { useEffect, useState } from "react";

const useAdmin = email => {
    const [isAdmin, setIsAdmin] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(()=> {
        if(email){

            fetch(`${process.env.REACT_APP_URL}/adminRoute?email=${email}`)
            .then(res => res.json())
            .then(data => {
                setIsAdmin(data.isAdmin);
                setLoading(false);
            })
            .catch(err => {
                console.log(err);
            })
        }
    }, [email, loading]);
    return [isAdmin, loading]
};
export default useAdmin;