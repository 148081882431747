import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../Context/AuthProvider";
import VideoPlayer from "../../../Component/VideoPlayer";

const Shikkha = () => {
  const { setScrollY, data } = useContext(AuthContext);
  const [newsData, setNewsData] = useState([]);

  useEffect(() => {
    const fetchLeadNews = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/category`,
          { params: { number: 5, category: "শিক্ষা" } }
        );
        const dt = response.data;
        setNewsData(dt);
      } catch (error) {
        console.error("Error fetching news titles:", error);
      }
    };

    fetchLeadNews();
  }, []);

  if (!data?.length) {
    return <></>;
  }

  return (
    <>
      {newsData?.length ? (
        <div className="my-10">
          <div className="flex items-center w-full my-8">
            <div className="w-full">
              <p className="w-full border-b border-gray-500"></p>
              <p className="w-full mt-1 border-b border-gray-500"></p>
            </div>
            <p className="px-3 text-3xl font-bold">শিক্ষা</p>
            <div className="w-full">
              <p className="w-full border-b border-gray-500"></p>
              <p className="w-full mt-1 border-b border-gray-500"></p>
            </div>
          </div>
          <div className="md:w-[80%] mx-auto">
            <div className="mb-4">
              <div className=" md:flex gap-10 justify-center items-center">
                <div className="w-full md:w-1/2">
                  <div className="w-full min-h-[160px] bg-gray-300 overflow-hidden rounded-md md:min-h-[250px] md:bg-gray-300">
                    {newsData[0]?.video ? (
                      VideoPlayer(newsData[0]?.video)
                    ) : (
                      <img
                        className="w-full rounded-md"
                        src={newsData[0]?.img}
                        alt="img"
                        loading="lazy"
                      />
                    )}
                  </div>
                </div>
                <div className="w-full md:w-1/2">
                  <div className="my-2">
                    <Link
                      to={`/news/${newsData[0]?._id}`}
                      state={newsData[0]}
                      className="text-2xl md:text-3xl font-bold my-3 text-blue-500 hover:text-red-500"
                    >
                      {newsData[0]?.title}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
              {newsData?.slice(1)?.map((d, index) => (
                <Link to={`/news/${d?._id}`} state={d} key={index} className="">
                  <img
                    className="w-full rounded-md"
                    src={d?.img}
                    alt="img"
                    loading="lazy"
                  />
                  <p className="text-md my-2 md:text-lg font-bold hover:text-blue-600">
                    {d?.title}
                  </p>
                </Link>
              ))}
            </div>
            <Link
              onClick={() => setScrollY(true)}
              to="/education"
              className="bg-blue-500 text-white font-bold py-2 px-6 flex m-10 justify-center items-center w-[80px] mx-auto rounded-md"
            >
              আরও
            </Link>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Shikkha;
