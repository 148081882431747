
import React from "react";
import ReactPlayer from "react-player";

const VideoPlayer = (url) => {
  
  return (
    <>
    
    <div className={`relative md:hidden ${url?.includes("youtube.com") && "pt-[56.25%]"}`}>
      <ReactPlayer
        className={`${url?.includes("youtube.com") && "absolute overflow-hidden top-0 left-0"}`}
        url={url}
        width="100%"
        height="100%"
        playing={url?.includes("youtube.com") ? false : true}
        controls
        config={{
          file: {
            attributes: {
              controlsList: "nofullscreen",
            },
          },
        }}
      />
    </div>
    <div className="relative pt-[56.25%] hidden md:block">
      <ReactPlayer
        className="absolute overflow-hidden top-0 left-0"
        url={url}
        width="100%"
        height="100%"
        playing={url?.includes("youtube.com") ? false : true}
        controls
        config={{
          file: {
            attributes: {
              controlsList: "nofullscreen",
            },
          },
        }}
      />
    </div>
    
    </>
  );
};

export default VideoPlayer;
